import {React, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useParams
} from "react-router-dom";

import './css/public.min.css';

//public
import {Navbar} from './components/navbar';
import {Footer} from './components/footer';

//landing
import {LandingTop} from './components/index/landingTop';
import {ExpertiseSection} from './components/index/expertiseSection';
import {ProjectsSection} from './components/index/projects_section';

//work page
import {WorkTop} from './components/work/workTop';
import {WorkProjectsSection} from './components/work/projects_section';

//contact page
import {ContactPage} from './components/contact/contact-content';

//about page
import {AboutPage} from './components/about/about-content';

//project page
import {ProjectPage} from './components/project/project-content';

//404 page
import {PageNotFoundPage} from './components/404/404';

import reportWebVitals from './reportWebVitals';

//scroll to top
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0, left: 0,
      //behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

function Home() {
  return (
    <div>
      <LandingTop />
      <ExpertiseSection />
      <ProjectsSection />
    </div>
  )
}

function Work() {
  return (
    <div>
      <WorkTop />
      <WorkProjectsSection />
    </div>
  )
}

function Contact() {
  return (
    <ContactPage />
  )
}


function About() {
  return (
    <AboutPage />
  )
}

function PageNotFound() {
  return (
    <PageNotFoundPage />
  )
}

function Project() {
  let { project } = useParams();
  project = project.toLowerCase().replace("-", " ");

  var projectInfo = require("./../public/projects/" + project + "/project_info.json");

  return (
    <ProjectPage project={project} projectInfo={projectInfo} />
  )
}

//<Route exact={true} path="*" component={PageNotFound} />

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <Navbar />

    <Switch>
      <Route exact={true} path="/" component={Home} />
      <Route path="/work" component={Work} />
      <Route path="/contact" component={Contact} />
      <Route path="/about" component={About} />
      <Route path="/project/:project" component={Project} />
      <Route component={PageNotFound} />
    </Switch>

    <Footer />
  </Router>
  ,document.getElementById('root')
);

//add back <Footer /> after fixing contact

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
