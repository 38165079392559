import React from 'react';
import {Link} from 'react-router-dom';

import {Project} from './../project.js';
import './../../css/work/work.min.css';
import your_project from './../../imgs/your-project.jpg';

export class WorkProjectsSection extends React.Component {
  render() {
    return (
      <div style={{background:"black", display: "flex", position:"relative", zIndex:"5"}}>
        <div className="workProjectsSectionContainer">
          <div className="workProjectsTitleBlock projectBlock hide-on-mobile">
            <div className="workProjectTitleBlockInnerContainer" style={{display: 'inline-block', overflow: 'hidden', lineHeight: "100px"}}>
              <h3 className="workProjectsTitle">Some work we love <br/>to share</h3>
              <div className="workProjectsTitleLine"></div>
            </div>
          </div>

          <Project project="Black Eagle" size={1} style={{marginLeft: "38px", marginRight: "0"}} />
          <Project project="DJ DAVIDV" size={2} />
          <Project project="Moms Basement" size={1} />

          <Link to="/contact" style={{display: "contents"}}>
            <div className="projectBlockContainer projectBlock projectBlock-1-1" style={{marginLeft: "38px", marginRight: "0"}}>
              <div style={{width: "100%", height: "100%", borderRadius: "20px", overflow: "hidden", position: "relative"}}>
                <img src={your_project} alt="blank paper" style={{transform: "none !important"}} />
                <span className="yourProjectInnerText">How will your project<br />look?</span>
              </div>
              <div className="projectBlockDetails">
                <span className="projectBlockProjectName">Your Project</span>
                <span className="projectBlockJobType">Is your project our next? Contact us!</span>
              </div>
            </div>
          </Link>

          <div className="readyToTalkContainer">
            <span>Like what you see?</span>
            <br />
            <Link to="/contact"><div className="contactButton">Get in touch</div></Link>
          </div>
        </div>
      </div>
    )
  }
}
