import React from 'react';
import {Link} from 'react-router-dom';

import macbookMockup from './../../imgs/macbookAir.png';
import watercolorVideo from './../../imgs/watercolor.mp4';

class ExpertisePoint extends React.Component {
  render() {
    return (
      <div className="expertisePointContainer">
        <h2 className="expertisePointTitle">{this.props.title}</h2>
        <p className="expertisePointText">{this.props.text}</p>
        {(this.props.statistic) && <i className="statistic show-on-desktop">{this.props.statistic}<br/></i>}
        <Link to={this.props.link} className="expertisePointLink"><div className="expertisePointLinkLine show-on-desktop"></div><span className="expertiseLinkName">{this.props.linkTitle}</span></Link>
      </div>
    )
  }
}

class ExpertiseBluePointBulletpoint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="expertiseBluePointBulletpoint">
        <h3>{this.props.title}</h3>
        <p>{this.props.text}</p>
      </div>
    )
  }
}

class ExpertiseBluePoint extends React.Component {
  render() {
    return (
      <div className="expertiseBluePointContainer">
        <h3 className="expertiseBluePointTitle">Expertise</h3>
        <div className="expertiseBluePointWrapper">
          <h3 className="expertiseBluePointTitle" style={{"-webkit-text-stroke-color": "white"}}>Expertise</h3>
          <span className="expertiseNumber show-on-desktop" style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", opacity: 0}}>03</span>
          <div className="expertiseBluePointBullerPointWrapper">
          <ExpertiseBluePointBulletpoint title="World-Class Graphics" text="We make aesthetics that people value and remember your brand for; designs that deliver results at the highest level." />
          <ExpertiseBluePointBulletpoint title="Advanced Websites" text="From design to development - we are here to do it right and create the website that fits your business’ specific needs, for the best results." />
          <ExpertiseBluePointBulletpoint title="UI/UX Design" text="We create seamless interactions, and memorable experiences to connect people with your brand." />
          </div>
        </div>
      </div>
    )
  }
}

export class ExpertiseSection extends React.Component {
  render() {
    return (
      <div id="expertiseSectionContainer">
        <div className="expertiseSection">
          <ExpertisePoint
          title={<span>Great work,<br/> for our great clients.</span>}
          text={<span>Your brand deserves a world-class website, and that’s why you are here.<br/>The design and functionality of your website has a huge impact on your future and current clients, don’t stay behind.</span>}
          linkTitle="See our work"
          link="/work"
          statistic="75% of consumers admit to making judgements on a company’s credibility based on the company’s website design."
          />

          <span className="expertiseNumber show-on-desktop" style={{position: "absolute", top: 0, left: 0, transform: "rotate(-90deg) translate(17%, -41%)"}}>01</span>
          <img className="show-on-desktop" src={macbookMockup} alt="floating macbook with ariose work on it" />
        </div>

        <div className="expertiseSection secondExpertisePoint">
          <ExpertisePoint
          title={<span>Gain the competitive advantage <br className="hide-on-mobile" />your business needs.</span>}
          text={<span>We are here to set your brand to success; creating a memorable experience<br/>for both your business partners and clients.<br/><br/>We get you results.<br /></span>}
          linkTitle="About us"
          link="/about"
          />

          <div className="ExpertisePointWatercolor">
            <video autoPlay muted loop className="show-on-desktop">
              <source src={watercolorVideo} type="video/webm" />
              Your browser does not suppoer the video.
            </video>
            <span className="expertiseNumber show-on-desktop" style=
            {{position: "absolute", bottom: "5%", left: 0, transform: "rotate(-90deg) translate(0, -50%)", opacity: 0.5}}>
              02
            </span>
          </div>

        </div>

        <ExpertiseBluePoint />
      </div>
    )
  }
}
