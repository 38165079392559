import React from 'react';

import './../../css/work/work.min.css';

export class WorkTop extends React.Component {
  render() {
    return (
      <div className="workTopContainer">
        <h2 className="workTopTitle">It's the small pieces,<br/>that make the big picture.</h2>
      </div>
    )
  }
}
