import React from 'react';

class FooterRow extends React.Component {
  render() {
    return (
      <div className="footerRow">
        <h4>{this.props.title}</h4>
        <ul>
          {this.props.content.map((item) => <li>{item}</li>)}
        </ul>
      </div>
    )
  }
}

export class Footer extends React.Component {
  render() {
    return (
      <footer id="footer">
        <div className="footerContentContainer">
          <FooterRow content={['+972 50 952 09 11', 'hello@ariose.com']} title="Get in touch" />
          <FooterRow content={['Northern District, Israel',  "1520500 HaZor'im"]} title="Send us cookies" />
          <FooterRow content={[<a href="#">Instagram</a>, <a href="#">Behance</a>, <a href="#">Facebook</a>]} title="Follow us" />
        </div>
        <span>© Ariose. All Rights Reserved</span>
      </footer>
    )
  }
}
