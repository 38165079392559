import React from 'react';
import Typed from 'typed.js';

import './../../css/index/index.min.css';

import landing_video from './../../imgs/landing-video6.mp4';
//import landing_video2 from './../../imgs/landing-video6.mp4';
import landing_video2 from './../../imgs/landing-video6.mp4';

export class LandingTop extends React.Component {
  componentDidMount() {
    var landingTopExpertise = new Typed('#expertiseTyper', {
      strings: ["digital presence", "conversion rate", "monthly traffic"],
      typeSpeed: 50,
      backSpeed: 40,
      backDelay: 2500,
      smartBackspace: false,
      loop: true,
      showCursor: true,
      //cursorChar: '_'
    });
  }

  render() {
    return (
      <div id="LandingTop">
        <div className="top-video-container">
          <video id="landingTopVideo" autoPlay muted loop>
            <source src={landing_video} type="video/mp4" />
            Your browser does not suppoer the video.
          </video>
        </div>
        <h2>
          We are <strong>Ariose</strong>,<br/>
          a creative digital agency here to<br/>
          boost your <strong id="expertiseTyper"></strong>.
        </h2>
      </div>
    )
  }
}
