import React from 'react';
import Typed from 'typed.js';
import $ from 'jquery';

import './../../css/contact/contact.min.css';

import image1 from './../../imgs/contact-image1.jpg';

import facebook_icon from './../../imgs/icons/facebook.svg';
import instagram_icon from './../../imgs/icons/instagram-logo.svg';
import twitter_icon from './../../imgs/icons/twitter.svg';

function scrollToForm() {
  var bottomOfThePage = document.getElementById("message");
  bottomOfThePage.scrollIntoView();
}

class TopText extends React.Component {
  render() {
    return (
      <div className="contactTopTextContainer" id="contactTopTextContainer">
        <h1>Let's take your<br/>brand to the<br/><strong>next level</strong></h1>

        <a href="#message" className="expertisePointLink">
          <div className="expertisePointLinkLine"></div>
          <span className="expertiseLinkName">Contact us</span>
        </a>
      </div>
    )
  }
}

class TopImage extends React.Component {
  render() {
    return (
      <div className="topImageContainer" id="topImageContainer">
        <img src={image1} alt="" className="topImage"/>
      </div>
    )
  }
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {currentContactMobile: "message"};
    this.toggleMobileContact = this.toggleMobileContact.bind(this);
  }

  componentDidMount() {

  }

  toggleMobileContact() {
    var fadeSpeed = 250; //speed in ms

    if(this.state.currentContactMobile == "message") {
      //$("#contactFormLeft").css({"display": "none", "opacity": "0"});
      //$("#contactFormRight").css({"display": "flex", "opacity": "1"});
      $("#contactFormRight").fadeOut(fadeSpeed);
      setTimeout(function() {
        $("#contactFormLeft").fadeIn(fadeSpeed);
      }, fadeSpeed + (fadeSpeed*0.2));
      this.setState({currentContactMobile: "social"});
    }
    else {
      $("#contactFormLeft").fadeOut(fadeSpeed);
      setTimeout(function() {
        $("#contactFormRight").fadeIn(fadeSpeed);
      }, fadeSpeed + (fadeSpeed*0.2));
      this.setState({currentContactMobile: "message"});
    }
  }

  render() {
    return (
      <div className="contactFormContainer">
        <div className="contactFormLeft" id="contactFormLeft">
          <div className="contactFormLeftContent">
            <h2 className="show-on-tablet">We'd love to hear from you!<br/>Send us a message using the<br/>form, or email us.</h2>

            <div class="contactEmailContainer">
              <span>Say hello</span>
              <br/>
              <span>hello@ariose.com</span>
            </div>

            <div className="contactSocial">
              <a href="#">
                <img src={facebook_icon} alt=""/>
              </a>

              <a href="#">
                <img src={instagram_icon} alt=""/>
              </a>

              <a href="#">
                <img src={twitter_icon} alt="" style={{marginRight: "0"}} />
              </a>
            </div>
          </div>
          <div className="contact-switch-button only-for-mobile" onClick={this.toggleMobileContact}>Message Us</div>
        </div>

        <div className="contactFormRight" id="contactFormRight">
          <form action="POST">
            <input type="text" name="name" placeholder="Name" autocomplete="off" required />
            <input type="text" name="company" placeholder="Company" autocomplete="off" />
            <input type="email" name="email" placeholder="Email" autocomplete="off" required />
            <input type="text" name="subject" placeholder="Subject" autocomplete="off" required />
            <textarea name="message" placeholder="Message" required></textarea>
            <button type="submit">Send</button>
            <div className="contact-switch-button only-for-mobile" onClick={this.toggleMobileContact}>Other Options</div>
          </form>
          <div className="arrow-left"></div>
        </div>
      </div>
    )
  }
}

export class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, true);
  }

  onScroll() {
    var windowHeight = window.innerHeight;
    var windowWidth = window.innerWidth;

    var scrollDistance = 1.5;
    var scrollArea = windowHeight;

    //top text and image
    const text = document.getElementById('contactTopTextContainer');
    const image = document.getElementById('topImageContainer');

    const formLeft = document.getElementById('contactFormLeft');
    const formRight = document.getElementById('contactFormRight');


    var scrollTop = window.pageYOffset || window.scrollTop;
    var scrollPercent = scrollTop/scrollArea || 0;

    text.style.top = (scrollPercent*window.innerHeight) + 'px';
    image.style.transform = "translateY(-" + scrollPercent*window.innerHeight + 'px)';

    //console.log(scrollPercent);
    if(scrollPercent >= 1) {
      var footerDistance = (windowHeight - scrollTop);
      var scrollAmount = windowHeight;
      //console.log("distance from footer: " + footerDistance);

      if(scrollPercent < scrollDistance) {
        formLeft.style.transform = "translateY(" + (scrollAmount) +  "px)";
        formRight.style.transform = "translateY(-" + (scrollAmount) +  "px)";
      }

      if(scrollPercent > 1.1) {
        formLeft.style.transitionDuration = "0s";
        formRight.style.transitionDuration = "0s";
      }
    }
    else {
      var scrollAmount = scrollPercent*window.innerHeight;
      formLeft.style.transform = "translateY(" + scrollAmount + 'px)';
      formRight.style.transform = "translateY(-" + scrollAmount + 'px)';

      formLeft.style.transitionDuration = "0.3s";
      formRight.style.transitionDuration = "0.3s";
    }
  }

  render() {
    return (
      <div>
        <div className="contactContentWrapperContainer" style={{height: "250vh", position: "relative", "scroll-snap-type": "y mandatory"}}>
          <div className="contactContentWrapper">
            <div className="contactContentContainer">
              <TopText />
              <TopImage />
            </div>
            <ContactForm />
          </div>
        </div>
        <div id="message" className="show-on-tablet" style={{height: "100vh", width: "100vw", zIndex: -1, position: "absolute", top: "150vh"}}></div>
      </div>
    )
  }
}
