import React from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';

export class Project extends React.Component {
  constructor(props) {
    super(props);

    //console.log(this.props.project + " style is: " + this.props.style);
  }

  projectInfo() {
    var projectInfo = require("./../../public/projects/" + this.props.project.toLowerCase() + "/project_info.json");
    return projectInfo;
  }

  render() {
    return (
      <Link to={"/project/" + this.props.project.toLowerCase().replace(" ", "-")} className={"projectBlockContainer projectBlock " + (this.props.size == 1 ? "projectBlock-1-1 projectBlock":"projectBlock-1-2")} style={this.props.style}>
        <div className="projectThumbnailContainer">
          <img src={process.env.PUBLIC_URL + "/projects/" + this.props.project.toLowerCase() + "/thumbnail.jpg"} alt={this.props.project.toLowerCase() + " project thumbnail"}/>
        </div>
        <div className="projectBlockDetails">
          <span className="projectBlockProjectName">{this.props.project}</span>
          <span className="projectBlockJobType">{this.projectInfo().work_type}</span>
        </div>
      </Link>
    )
  }
}
