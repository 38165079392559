import React from 'react';
import './../../css/project/project.min.css';

import {useParams, useRouteMatch, withRouter} from 'react-router-dom';




// const projectID = ({match}) => (
//   <h3>
//   {match.params.project.id}
//   </h3>
// )

// function Project() {
//   const params = useParams();
//   console.log("project id is: " + params);
//   return params;
// }

//var project = "Black Eagle";

function isOdd(num) { return num % 2;}

class ProjectCover extends React.Component {
  render() {
    return (
      <div className="project-cover-wrapper">
        <div className="project-cover-container">
          <img src={process.env.PUBLIC_URL + "/projects/" + this.props.project + "/thumbnail.jpg"} className="project-cover-image" alt=""/>
          <h1 className="project-client">{this.props.projectInfo.name}</h1>
          {this.props.projectInfo.website && <a href={this.props.projectInfo.website} className="project-website-link show-on-desktop">Visit Website</a>}
        </div>
      </div>
    )
  }
}

class ProjectInformation extends React.Component {
  render() {
    return (
      <div className="project-information-container">
        <h2 className="project-information-title show-on-tablet">INFORMATION</h2>
        {this.props.projectInfo.website && <a href={this.props.projectInfo.website} className="project-website-link only-for-tablet">Visit Website</a>}
        {this.props.projectInfo.website && <a href={this.props.projectInfo.website} className="project-website-link only-for-mobile">Visit Website</a>}
        <div className="project-information-separating-line"></div>
        <div className="project-details-container">
          <div className="project-detail">
            <h3 className="project-detail-header">CLIENT</h3>
            <span className="project-detail-text">{this.props.projectInfo.name}</span>
          </div>

          <div className="project-detail">
            <h3 className="project-detail-header">WORK</h3>
            <span className="project-detail-text">{this.props.projectInfo.work_type}</span>
          </div>

          <div className="project-detail">
            <h3 className="project-detail-header">DATE</h3>
            <span className="project-detail-text">{this.props.projectInfo.date + ", " + this.props.projectInfo.year}</span>
          </div>
        </div>
      </div>
    )
  }
}

class ProjectImages extends React.Component {
  constructor(props) {
    super(props);

    //this.processProjectImages = this.processProjectImages.bind(this);
  }

  projectImages() {
    var project = this.props.project;

    var project_map = require("./../../../public/projects/" + project + "/project_map.json");
    project_map = Object.values(project_map);

    var imagesJSX = '<img src="' + process.env.PUBLIC_URL + '/projects/' + project + '/opening.png" class="project-image" alt="' + project + '"/>';

    project_map.forEach(function(value) {
      //console.log(value);
      if(Array.isArray(value) == false) {
        //if not array
        //imagesJSX.push("" + <img src={process.env.PUBLIC_URL + "/projects/" + project + "/imgs/" + value} className="project-image" alt={project + " " + value.split(".")[0]}/> + "");
        //console.log("not array:" + value);
        imagesJSX = imagesJSX +
        '<div class="project-image-container">' +
          '<span class="project-image-title">' + value.split(".")[0] + '</span>' +
          '<img src="' + process.env.PUBLIC_URL + '/projects/' + project + '/imgs/' + value + '" class="project-image" alt="' + project + " " + value.split(".")[0] + '"/>' +
        '</div>';
      }
      else {
        //if value is array
        var webImagesJSX = "";
        var imageCount = 1;
        value.forEach(function(image, index) {
          if((index == value.length && isOdd(index) == 1) == false) {
            //if 2 images can be put in one culomn
            if(imageCount < 2) {
              //console.log(image + " | Packing " + imageCount + "/2");
              webImagesJSX = webImagesJSX +
              '<div class="project-web-image-wrapper">' +
                '<div class="project-web-image-container">' +
                  '<img src="' + process.env.PUBLIC_URL + '/projects/' + project + '/imgs/web/' + image + '" class="project-image" alt="' + project + " " + image.split(".")[0] + '"/>' +
                '</div>' +
                '<div class="project-web-image-label"><strong>' + image.split(".")[0] + '</strong></div>' +
              '</div>';
              imageCount++;
            }
            else {
              webImagesJSX = webImagesJSX +
              '<div class="project-web-image-wrapper">' +
                '<div class="project-web-image-container">' +
                  '<img src="' + process.env.PUBLIC_URL + '/projects/' + project + '/imgs/web/' + image + '" class="project-image" alt="' + project + " " + image.split(".")[0] + '"/>' +
                '</div>' +
                '<div class="project-web-image-label"><strong>' + image.split(".")[0] + '</strong></div>' +
              '</div>';

              //console.log("packed 2.");

              webImagesJSX = '<div class="web-images-container">' + webImagesJSX + '</div>';
              imagesJSX = imagesJSX + webImagesJSX;
              webImagesJSX = "";
              imageCount = 1;
            }
          }
          else {
            //if the index is odd and last in array
            //console.log("uhm, it can't be odd, yet this message prints, ay?");
          }
        })
      }
    })

    return (
      <div className="project-images-container" dangerouslySetInnerHTML={{__html: imagesJSX}}></div>
    )
  }

  render() {
    return (
      this.projectImages()
    )
  }
}

class ProjectNavigation extends React.Component {
  render() {
    return(
      <div className="project-navigation-container">
        <div className="project-navigation-links-container">
          <a href="#" className="project-navigation-button">Next</a>
          <a href="#" className="project-navigation-button">Prev</a>
        </div>
      </div>
    )
  }
}

export class ProjectPage extends React.Component {
  render() {
    return (
      <div className="projectContentContainer">
        <ProjectCover project={this.props.project} projectInfo={this.props.projectInfo} />
        <ProjectInformation project={this.props.project} projectInfo={this.props.projectInfo} />
        <ProjectImages project={this.props.project} />
        <ProjectNavigation project={this.props.project} />
      </div>
    )
  }
}
