import React from 'react';
import $ from 'jquery';

import './../../css/404/404.min.css';

export class PageNotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    this.titleHtml = "";
    //plz for the love of god, use an odd number
    var layerCount = 7;
    for(var i = 0; i < layerCount; i++ ) {
      this.titleHtml = this.titleHtml + '<h2 class="_404">404</h2>';
    }
  }

  animateText() {

  }

  componentDidMount() {
    //on mouse move
    $(document).on('mousemove', 'body', function (event) {
        var x = event.pageX;
        var y = event.pageY;

        var centerPoint = {
          "x": window.innerWidth/2,
          "y": window.innerHeight/2
        };

        var moveDistance = {
          "x": (centerPoint.x - x)/55,
          "y": (centerPoint.y - y)/55
        }

        //select all 404 elements
        var _404 = document.getElementsByClassName("_404");

        for(var i = 0; i < _404.length; i++) {
          var stationaryLayer = parseInt(_404.length/2);
          var position = i;
          //var position = i > 0 ? i : 1;
          //console.log("stationary layer is: " + stationaryLayer);

          if(i < stationaryLayer) {
            position = negNum(position - stationaryLayer);
          }
          else if(i == stationaryLayer) {
            position = 0;
          }
          else {
            position = (position - stationaryLayer);
          }

          //console.log("position is: " + position);

          _404[i].style.left =  ((position)*moveDistance.x) + "px";
          _404[i].style.top =  ((position)*moveDistance.y) + "px";
        }

        //console.log("-------------");
    });

    $("._404-title").click(function() {
      $("._404-container").append('<h2 class="_404">404</h2>');
    })
  }

  render() {
    return (
      <div className="_404-content-container" id="_404-content-container">
        <div className="_404-container" dangerouslySetInnerHTML={{ __html: this.titleHtml }}></div>

        <h1 className="_404-title">Page Not Found</h1>
        <a href="/" className="back-home-button">Back To <u>Home</u></a>
      </div>
    )
  }
}

function negNum(num) {
  return -Math.abs(num);
}
