import React from 'react';

import './../../css/about/about.min.css';
import floating_image from './../../imgs/about-image.jpg';

export class AboutPage extends React.Component {
  constructor(props) {
    super(props);

    this.onScroll = this.onScroll.bind(this);
  }

  componentWillMount() {

  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, true);
  }

  onScroll() {
    var windowHeight = window.innerHeight;
    var windowWidth = window.innerWidth;
    var scrollArea = windowHeight*2;

    //the two "abouts"
    const title1_1 = document.getElementsByClassName('aboutTitle1')[0];
    const title1_2 = document.getElementsByClassName('aboutTitle1')[1];

    //the two "our studio"
    const title2_1 = document.getElementsByClassName('aboutTitle2')[0];
    const title2_2 = document.getElementsByClassName('aboutTitle2')[1];

    //image
    const image = document.getElementById("aboutImage");

    //text
    const text = document.getElementById("aboutText");


    var scrollTop = window.pageYOffset || window.scrollTop;
    var scrollPercent = scrollTop/scrollArea || 0;

    title1_1.style.left = scrollPercent*window.innerWidth + 'px';
    title1_2.style.left = scrollPercent*window.innerWidth + 'px';

    title2_1.style.right = scrollPercent*window.innerWidth + 'px';
    title2_2.style.right = scrollPercent*window.innerWidth + 'px';

    //console.log(scrollPercent);


    if(scrollPercent >= 0.3 ) {
      var imageOpacity = 1.8-(7*(scrollPercent - 0.3));
      if(imageOpacity >= 0) {
        //image.style.display = "block";
        image.style.opacity = imageOpacity;

        text.style.display = "none";
        text.style.opacity = 0;
      }
      else {
        var textOpacity = ((-0.55)*6)+(5*scrollPercent);
        if(textOpacity >= 0) {
          text.style.display = "block";
          text.style.opacity = textOpacity;

          image.style.opacity = 0;
          //image.style.display = "none";
        }
      }
    }
  }

  render() {
    return (
      <div className="aboutContentCointainer">
        <div className="aboutContent">
          <div className="aboutTextContainer" id="aboutText">
            <h1>About</h1>
            <p>Ariose has one of the most creative teams of graphic designers, crafting magic and representing brands with highly innovative designs. From inventing to interpretation, Ariose gives its clients the most exotic value.
<br /><br />
We are of the notion that designs travel faster and beyond the computer screen. Designs appear in the world around us, and at Ariose, we take care of all your graphic design needs to make the world around you look aesthetically creative and pleasing. With this core value, Ariose is dedicated to giving its clients the grand stage, helping you shape the way your brand is perceived on the international stage. We focus on creating compelling websites that keep your audience engaged with artistic and astonishing designs.
<br/><br/>
At Ariose, we believe there is a better way to do graphical designs. A less invasive, more valuable way where our clients’ are indeed represented by the designs we create. Creating Web Designs, UI/UX, Branding (logos, business cards, letterheads), flyers, product design and ads have never been strange to us. We are obsessively passionate about helping you bring out the artistic side to your brand.
<br /><br />
We are excited to be working on your project soon.</p>
          </div>
          <div className="aboutImageWrapper">
            <div className="aboutImageContainer">
              <img src={floating_image} id="aboutImage" alt=""/>
              <h2 className="aboutTitle"><strong className="aboutTitle1">About</strong><br/><strong className="aboutTitle2">Our Studio</strong></h2>
            </div>
            <h2 className="aboutTitle aboutOuterTitle"><strong className="aboutTitle1">About</strong><br/><strong className="aboutTitle2">Our Studio</strong></h2>
          </div>
        </div>
      </div>
    )
  }
}
