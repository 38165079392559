import React from 'react';
import {Link} from 'react-router-dom';

import {Project} from './../project';

import './../../css/index/index.min.css';

export class ProjectsSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="projectsSection">
        <div className="selectedProjectsBlock projectBlock">
          <h3 className="selectedProjectsTitle">Selected <br className="show-on-tablet" />Projects</h3>
          <Link to="/work" className="expertisePointLink hide-on-mobile">
            <div className="expertisePointLinkLine show-on-desktop"></div>
            <span className="expertiseLinkName">All projects</span>
          </Link>
        </div>

        <Project project="Black Eagle" size={1} style={{marginLeft: "38px", marginRight: "0"}} />
        <Project project="DJ DAVIDV" size={2} />

        <Link to="/work" className="expertisePointLink only-for-mobile">
          <span className="expertiseLinkName">All projects</span>
        </Link>

        <div className="readyToTalkContainer">
          <span>Ready to talk?</span>
          <br />
          <Link to="/contact"><div className="contactButton">Contact</div></Link>
        </div>
      </div>
    )
  }
}
