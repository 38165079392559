import React from 'react';
import logo from '../imgs/logo.svg';
import $ from 'jquery';
import {NavLink, Link} from 'react-router-dom';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {active: false};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(state) {
    if(this.state.active) {
      //deactivate
      this.setState({ active: false });

      document.getElementById("menu").classList.remove('menu-active');
      document.getElementById("menu-inner").classList.remove('menu-inner-active');

      //re-enable scrolling
      $(document).unbind('scroll');
      $('body').css({'overflowY':'overlay'});
    }
    else {
      //activate
      this.setState({ active: true });

      document.getElementById("menu").classList.add('menu-active');
      document.getElementById("menu-inner").classList.add('menu-inner-active');

      //disable scrlling
      $('body').css({'overflowY':'hidden'});
      $(document).bind('scroll',function () {
         window.scrollTo(0,0);
      });
    }
  }

  componentDidMount() {
    $("#logo").click(() => {
      this.setState({ active: true });
    })
  }

  render() {
    return (
      <div id="menu">
        <div id="menuButton" onClick={this.handleClick}>
          <span></span>
          <span style={{width: "70%", marginLeft: "30%"}} className="middleBurger"></span>
          <span style={{width: "90%", marginLeft: "10%"}}></span>
        </div>

        <div id="menu-inner" className="menu-inner">
          <div className="menu-left">
            <ul className="menu-options">
              <NavLink to="/work" onClick={this.handleClick} className="navbarOption" activeClassName="activeNavbarOption"><li>Work</li></NavLink>
              <NavLink to="/contact" onClick={this.handleClick} className="navbarOption" activeClassName="activeNavbarOption"><li>Contact</li></NavLink>
              <NavLink to="/about" onClick={this.handleClick} className="navbarOption" activeClassName="activeNavbarOption"><li>About</li></NavLink>
            </ul>
          </div>

          <div className="menu-right show-on-tablet" onClick={this.handleClick}></div>
        </div>
      </div>
    )
  }
}

export class Navbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav id="navbar">
        <Link to="/" onClick={() => {$("#menuButton").click()}}>
          <img src={logo} alt="ariose logo" id="logo" draggable="false" />
        </Link>
        <Menu />
      </nav>
    )
  }
}

$(document).ready(() => {
  var prevScrollPos = window.pageYOffset;
  window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
    if(prevScrollPos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
      document.getElementById("navbar").style.opacity = "1";
      prevScrollPos = currentScrollPos;
    }
    else {
      document.getElementById("navbar").style.top = "-75px";
      document.getElementById("navbar").style.opacity = "0";
      prevScrollPos = currentScrollPos;
    }
  }
})
